import React from "react";
import './main.css'
//API for data
import {GetData} from "./GetData";
//React JS Bootstrap Tags
import {Col, Row, Card, Spinner} from "react-bootstrap";
//Datatable Files
import Datatable from "react-data-table-component";
import "./css/dataTable.css"
import {CSVLink} from "react-csv";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            usersData: [],
            dataLoader: true,
        }
        this.loadAllUsers = this.loadAllUsers.bind(this)
    }

    componentDidMount() {
        this.loadAllUsers()
    }

    loadAllUsers() {
        GetData('/frontCall/getAllUsers', this.state.userToken)
            .then(result => {
                this.setState({
                    usersData: result.response,
                    headers: [
                        {label: "Full Name", key: "full_name"},
                        {label: "Email", key: "email"},
                        {label: "Gift Aid", key: "gift_aid"},
                        {label: "Home Address", key: "home_address"},
                        {label: "Post Code", key: "post_code"},
                        {label: "Subscribe", key: "subscribe"},
                    ],
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">Full Name</h6>,
                            selector: (row) => row.full_name,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Email</h6>,
                            selector: (row) => row.email,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Gift Aid</h6>,
                            selector: (row) => (row.gift_aid == 1) ? "Yes" : "No",
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Home Address</h6>,
                            selector: (row) => row.home_address,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Post Code</h6>,
                            selector: (row) => row.post_code,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Subscribe</h6>,
                            selector: (row) => (row.subscribe == 1) ? "Yes" : "No",
                            sortable: true
                        }
                    ],
                    dataLoader: false,
                });
            });
    }

    render() {
        document.title = this.props.title;
        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Users</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <div className={"text-right mb-3"}>
                                        <CSVLink
                                            filename={"thesalaamcentreapp-users.csv"}
                                            className="btn btn-primary"
                                            headers={this.state.headers}
                                            data={this.state.usersData}>Export CSV</CSVLink>
                                    </div>
                                    {this.state.dataLoader ?
                                        <div className="api-data-loader">
                                            <Spinner animation="grow" variant="info"/>
                                        </div> :
                                        <Datatable
                                            columns={this.state.columns}
                                            data={this.state.usersData}
                                            pagination
                                            highlightOnHover
                                        />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
        )
    }
}

export default Users;